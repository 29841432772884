<template>
  <div id="general-dashboard">
    <b-card class="col-12 flex-wrap">
      <h3 style="margin-bottom: 15px">{{ t("title") }}</h3>
      <p>{{ t("subtitle") }}</p>
    </b-card>
    <b-container class="p-0" fuild style="max-width: 2560px">
      <b-row style="padding: 0px 0px 0px 15px">
        <b-col
          :key="service.id"
          class="row-eq-height entranceFromBottom col-xxl-4 col-xxxl-3"
          sm="6"
          v-for="service in rows"
        >
          <dashboard-card
            :cardTitle="service.cardTitle"
            :image="service.image"
            :cardSubtitle="service.cardSubtitle"
            :gui_path="service.gui_path"
            :items="service.items"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DashboardCard from "./DashboardCard";
import Config from "@/config";
export default {
  name: "general-dashboard",
  components: { DashboardCard },
  mixins: [],
  props: {},
  data() {
    return {
      currentPage: 1,
      services: [],
      visible_for_permissions: [],
      operator: "AND",
      available_services: Config.available_services,
      available_products: Config.available_products,
      available_services_ns: Config.available_services_ns,
      available_services_ds: Config.available_services_ds,
      visible_for_services: [],
      visible_for_products: [],
      visible_for_services_ns: [],
      visible_for_services_ds: [],
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.services = Config.dashboard_cards;
    },
    checkRolePermissions(visible_for_permissions, operator) {
      let arr, obj, res, op;
      obj = visible_for_permissions;
      arr = this.$store.getters["auth/userPermissions"];
      switch (operator) {
        case "AND":
          op = true;
          break;
        case "OR":
          op = false;
          break;
        default:
          op = true;
          break;
      }
      res = op;
      if (_.isEmpty(obj)) return true;
      for (let index = 0; index < obj.length && res == op; index++) {
        res = _.some(arr, (v) =>
          new RegExp(v.path).test(obj[index].path) &&
          _.isEqual(
            _.intersection(v.actions, obj[index].actions).sort(),
            obj[index].actions
          )
            ? true
            : false
        );
      }
      return res;
    },
    getService(name) {
      return this.$store.getters["settings/getServiceState"](name);
    },
    getServiceNS(name) {
      if (this.$store.getters["networksecure/status/networkSecureSnifferMode"]) {
        return (name == 'Firewall' || name == 'Activation' || name == 'AntiVirus')
          ? false
          : this.$store.getters["settings/getProductState"]("NetworkSecure") && this.$store.getters["settings/getServiceStateNS"](name);
      } else {
        return (this.$store.getters["settings/getProductState"]("NetworkSecure") && this.$store.getters["settings/getServiceStateNS"](name));
      }
    },
    getServiceDS(name) {
      return (
        this.$store.getters["settings/getProductState"]("DNSecure") &&
        this.$store.getters["settings/getServiceStateDS"](name)
      );
    },
    getProduct(name) {
      return this.$store.getters["settings/getProductState"](name);
    },

    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
    isArray(value) {
      return value && typeof value === "object" && value.constructor === Array;
    },

    checkServices(visible_services) {
      let vm = this;
      if (!!!visible_services) return true;

      let services = [];
      if (this.isString(visible_services)) {
        if (!!!visible_services) services = this.available_services;
        else if (vm.getService(visible_services))
          services.push(visible_services);
      } else if (this.isArray(visible_services)) {
        if (visible_services.length == 0) services = this.available_services;
        //check if visible with given services
        else
          services = visible_services.filter((item) => {
            return vm.getService(item);
          });
      } else {
        let tp = typeof visible_services;
        console.error(
          "checkServices(): incorrect type for 'visible_services': found " +
            tp +
            ", expected [String, Array]"
        );
      }

      return services.length > 0;
    },
    checkServicesNS(visible_services_ns) {
      let vm = this;
      if (!!!visible_services_ns) return true;

      let services = [];
      if (this.isString(visible_services_ns)) {
        if (!!!visible_services_ns) services = this.available_services_ns;
        else if (vm.getServiceNS(visible_services_ns))
          services.push(visible_services_ns);
      } else if (this.isArray(visible_services_ns)) {
        if (visible_services_ns.length == 0)
          services = this.available_services_ns;
        //check if visible with given services
        else
          services = visible_services_ns.filter((item) => {
            return vm.getServiceNS(item);
          });
      } else {
        let tp = typeof visible_services_ns;
        console.error(
          "checkServicesNS(): incorrect type for 'visible_services_ns': found " +
            tp +
            ", expected [String, Array]"
        );
      }

      return services.length > 0;
    },
    checkServicesDS(visible_services_ds) {
      let vm = this;
      if (!!!visible_services_ds) return true;

      let services = [];
      if (this.isString(visible_services_ds)) {
        if (!!!visible_services_ds) services = this.available_services_ds;
        else if (vm.getServiceDS(visible_services_ds))
          services.push(visible_services_ds);
      } else if (this.isArray(visible_services_ds)) {
        if (visible_services_ds.length == 0)
          services = this.available_services_ds;
        //check if visible with given services
        else
          services = visible_services_ds.filter((item) => {
            return vm.getServiceDS(item);
          });
      } else {
        let tp = typeof visible_services_ds;
        console.error(
          "checkServicesDS(): incorrect type for 'visible_services_ds': found " +
            tp +
            ", expected [String, Array]"
        );
      }

      return services.length > 0;
    },
    filterCardItems(cardTitle, cardItems){
      if (cardTitle !== "general_management"){
        return cardItems;
      }
      return cardItems.filter((cardItem) => cardItem.title !== "data_export_title" || !this.isIoT);
    },
    checkProducts(visible_products) {
      let vm = this;
      if (!!!visible_products) return true;

      let products = [];
      if (this.isString(visible_products)) {
        if (!!!visible_products) products = this.available_products;
        else if (vm.getProduct(visible_products))
          products.push(visible_products);
      } else if (this.isArray(visible_products)) {
        if (visible_products.length == 0) products = this.available_products;
        //check if visible with given services
        else
          products = visible_products.filter((item) => {
            return vm.getProduct(item);
          });
      } else {
        let tp = typeof visible_products;
        console.error(
          "checkProducts(): incorrect type for 'visible_products': found " +
            tp +
            ", expected [String, Array]"
        );
      }

      return products.length > 0;
    },
  },
  computed: {
    rows() {
      let ret = [];
      this.services.forEach((value, key) => {
        let item = {
          id: value.title,
          cardTitle: value.title,
          image: value.image,
          cardSubtitle: value.subtitle,
          gui_path: value.gui_path,
          items: value.items,
        };
        item.items = this.filterCardItems(item.cardTitle, item.items);
        if (item.gui_path != "") {
          typeof Config.gui_paths_required_api_endpoints[item.gui_path] !=
          "undefined"
            ? (this.visible_for_permissions =
                Config.gui_paths_required_api_endpoints[item.gui_path])
            : (this.visible_for_permissions = []);
        } else {
          let em = [];
          item.items.forEach((element) => {
            if (typeof Config.gui_paths_required_api_endpoints[element.gui_path] != "undefined"){
              Config.gui_paths_required_api_endpoints[element.gui_path].forEach(
                (el) => {
                  em.push(el);
                }
              );
            }
          });
          this.visible_for_permissions = em;
          this.operator = "OR";
        }
        if (value.hasOwnProperty("visible_for_services")) {
          this.visible_for_services = value.visible_for_services;
          this.visible_for_products = [];
          this.visible_for_services_ns = [];
          this.visible_for_services_ds = [];
        } else if (value.hasOwnProperty("visible_for_products")) {
          this.visible_for_services = [];
          this.visible_for_products = value.visible_for_products;
          this.visible_for_services_ns = [];
          this.visible_for_services_ds = [];
        } else if (value.hasOwnProperty("visible_for_services_ns")) {
          this.visible_for_services = [];
          this.visible_for_products = [];
          this.visible_for_services_ns = value.visible_for_services_ns;
          this.visible_for_services_ds = [];
        } else if (value.hasOwnProperty("visible_for_services_ds")) {
          this.visible_for_services = [];
          this.visible_for_products = [];
          this.visible_for_services_ns = [];
          this.visible_for_services_ds = value.visible_for_services_ds;
        } else {
          this.visible_for_services = [];
          this.visible_for_products = [];
          this.visible_for_services_ns = [];
          this.visible_for_services_ds = [];
        }
        if (this.permission_granted && this.make_visible) {
          ret.push(item);
        }
      });
      return ret;
    },
    permission_granted() {
      return this.checkRolePermissions(
        this.visible_for_permissions,
        this.operator
      );
    },
    make_visible() {
      return (
        this.checkServices(this.visible_for_services) &&
        this.checkProducts(this.visible_for_products) &&
        this.checkServicesNS(this.visible_for_services_ns) &&
        this.checkServicesDS(this.visible_for_services_ds)
      );
    },
    asm_s_parental_control() {
      return this.getService("parental_control");
    },
    asm_s_threat_protection() {
      return this.getService("threat_protection");
    },
    asm_s_ads_free() {
      return this.getService("ads_free");
    },
    asm_s_auto_notice() {
      return this.getService("auto_notice");
    },
    asm_s_antispam_out() {
      return this.getService("antispam_out");
    },
    asm_s_antispam_in() {
      return this.getService("antispam_in");
    },
    asm_s_firewall() {
      return this.getService("firewall");
    },
    asm_s_webproxy() {
      return this.getService("webproxy");
    },
    asm_s_wolf() {
      return this.getService("wolf");
    },

    asm_p_NetworkSecure() {
      return this.getProduct("NetworkSecure");
    },
    asm_p_RouterSecure() {
      return this.getProduct("RouterSecure");
    },
    asm_p_EndpointSecure() {
      return this.getProduct("EndpointSecure");
    },

    ns_s_AntiVirus() {
      return this.getService("AntiVirus");
    },
    ns_s_AntiPhishing() {
      return this.getService("AntiPhishing");
    },
    ns_s_AntiBotnet() {
      return this.getService("AntiBotnet");
    },
    ns_s_ContentFilter() {
      return this.getService("ContentFilter");
    },
    ns_s_AdsFree() {
      return this.getService("AdsFree");
    },
    ns_s_Firewall() {
      return this.getService("Firewall");
    },
    ns_s_SMTPFilterIn() {
      return this.getService("SMTPFilter.In");
    },
    ns_s_SMTPFilterOut() {
      return this.getService("SMTPFilter.Out");
    },
    ns_s_MailFilter() {
      return this.getService("MailFilter");
    },
    ns_s_IMAPFilter() {
      return this.getService("IMAPFilter");
    },
    ns_s_Activation() {
      return this.getService("Activation");
    },
    ns_s_WOLF() {
      return this.getService("WOLF");
    },
    ns_s_WebProxy() {
      return this.getService("WebProxy");
    },
    isIoT() {
      return this.$store.getters['settings/isIOT'];
    }
  },
};
</script>

<style lang='scss'>
#general-dashboard {
  .card-body {
    margin-top: 0px;
  }
}
</style>
