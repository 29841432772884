<template>
  <b-card
    id="card-box"
    :img-src="image"
    img-alt="Image"
    img-top
    class="card-container"
    img-height="100em"
  > 
    <h3 style="margin-bottom: 15px" v-if="gui_path != ''"><a :href="getGuiPath(gui_path)">{{ t(cardTitle) }}</a></h3>
    <h3 style="margin-bottom: 15px" v-else>{{ t(cardTitle) }}</h3>
    <p>{{ t(cardSubtitle) }}</p>
    <template v-for="service in items">
      <dashboard-card-services
        :key="service.title"
        :title="service.title"
        :paragraph="service.content"
        :gui_path="service.gui_path"
        :visible_for_permissions='getVisibleForPermissions(service)'
        :visible_for_services_ns='getVisibleForNsServices(service)'
        :visible_for_packages='getVisibleForPackages(service)'
      />
    </template>
  </b-card>
</template>

<script>
import DashboardCardServices from "./DashboardCardServices";
import Config from '@/config'

export default {
  name: "dashboard-card",
  mixins: [],
  components: {DashboardCardServices},
  props: {
    cardTitle: {
      type: String,
      default: "",
    },
    cardSubtitle: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    gui_path: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: [],
    },
  },
  methods: {
    getVisibleForPermissions(service){
       return typeof Config.gui_paths_required_api_endpoints[service.gui_path] != 'undefined' ? Config.gui_paths_required_api_endpoints[service.gui_path] : [];
    },
    getVisibleForNsServices(service){
       return typeof service.visible_for_services_ns != 'undefined' ? service.visible_for_services_ns : [];
    },
    getVisibleForPackages(service){
       return typeof service.visible_for_packages != 'undefined' ? service.visible_for_packages : [];
    },
    getGuiPath(gui_path) {
      return (gui_path != '/profile/edit') ? "/#" + gui_path : "/#" + gui_path + "/" + this.$store.getters['auth/manager'] 
    }
  }
};
</script>

<style lang="scss" scoped>
.card-container {
  margin-bottom: 1em;
  margin-top: 1em;
  max-width: 30rem;
}
.card {
  font-family: "AmpleSoft Pro Medium", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  border-radius: 0.5em;
}
.avatar-container {
  display: flex;
  justify-content: center;
  min-height: 1em;
  margin-top: -4em;
}
.card-avatar {
  display: flex;
  justify-content: center;
  margin: -60px 80px 0px 20px;
}
.group-resume {
  padding: 1em 0.5em 0 0.5em;
}
.break-line {
  height: 1.5px;
  margin-left: -15px;
  background-color: #e3e3e3;
}
.list-icon {
  margin-top: 0.6em;
}
a {
  text-decoration: underline;
}
</style>