<template>
  <p v-if="permission_granted && title != 'configuration_title' && make_visible"><a :href="'/#' + gui_path">{{t(title)}}</a>: {{ t(paragraph) }}</p>
  <i18n path="dashboard-card-services.configuration_content" tag="p" v-else-if="permission_granted && title == 'configuration_title' && make_visible">
    <template v-slot:configuration_title>
      {{ t('dashboard-card-services.configuration_title') }}
    </template>
    <template v-slot:category_management_menu>
      <a href="/#/isp_services/configuration/category-management">{{ t('dashboard-card-services.category_management') }}</a>
    </template>
    <template v-slot:profiles_menu>
      <a href="/#/isp_services/configuration/profiles">{{ t('dashboard-card-services.profiles') }}</a>
    </template>
    <template v-slot:notifications_menu>
      <a href="/#/isp_services/configuration/notifications">{{ t('dashboard-card-services.notifications') }}</a>
    </template>
  </i18n>
</template>

<script>
import permissions from "@/mixins/permissions";
import authorization from "@/mixins/authorization";

export default {
  name: "dashboard-card-services",
  mixins: [permissions, authorization],
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    paragraph: {
      type: String,
      default: "",
    },
    gui_path: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: underline;
}
</style>